<template>
  <v-container>
    <v-card v-if="survey">
      <v-card-title class="bg-primary text-uppercase">{{ survey.title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="auto">
            <v-navigation-drawer permanent floating>
              <v-list dense>
                <draggable
                  :value="survey.visiblePages"
                  @input="rearrangePages(survey, $event)"
                  @start="drag = true"
                  @end="drag = false"
                  handle=".page-handle"
                >
                  <div v-for="page in survey.visiblePages" :key="page.name">
                    <page-form :page="page" @click="goToPage" @delete="removePage($event)">
                      <template v-slot:[`prepend`]>
                        <v-list-item-icon class="align-self-center mr-2 page-handle">
                          <v-icon>mdi-drag</v-icon>
                        </v-list-item-icon>
                      </template>
                    </page-form>
                    <draggable
                      :value="page.questions"
                      @input="rearrangeQuestions(page, $event)"
                      @start="drag = true"
                      @end="drag = false"
                      handle=".question-handle"
                    >
                      <v-list-item v-for="question in page.questions" :key="question.name">
                        <v-list-item-icon class="align-self-center mr-2 question-handle">
                          <v-icon>mdi-drag</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ question.no }} {{ question.title }} </v-list-item-title>
                      </v-list-item>
                    </draggable>
                    <v-divider></v-divider>
                  </div>
                  <v-list-item class="text-button" @click="addPage">+ {{ $t("survey.builder.add_page") }} </v-list-item>
                </draggable>
              </v-list>
            </v-navigation-drawer>
          </v-col>

          <v-divider vertical></v-divider>
          <v-col>
            <Survey :survey="survey"></Survey>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item class="text-button my-2" v-bind="attrs" v-on="on"
                  >+ {{ $t("survey.builder.add_question") }}
                </v-list-item>
              </template>
              <v-list>
                <v-list-item
                  v-for="question in questionTypes"
                  :key="question.name"
                  @click="addQuestion(question.type, question.renderAs)"
                >
                  <v-list-item-title class="text-capitalize">
                    {{ $t("survey.builder.question_types." + question.id) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn :loading="saving" @click="save" color="accent" block>{{ $t("dialog.save") }}</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :loading="saving" @click="exit" color="secondary" block>{{ $t("dialog.exit") }}</v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn :loading="saving" @click="saveAndExit" color="primary" block>{{ $t("dialog.save_and_exit") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article"></v-skeleton-loader>
    <v-dialog persistent v-model="showDialog" max-width="800px">
      <question-form :question="selectedQuestion" @close="showDialog = false"></question-form>
    </v-dialog>
  </v-container>
</template>

<script>
import { Survey } from "survey-vue-ui";
import gql from "graphql-tag";
import PageForm from "./components/forms/PageForm.vue";
import QuestionForm from "./components/forms/QuestionForm.vue";
import Draggable from "vuedraggable";
import survey from "./mixins/survey";
import "survey-core/survey.i18n";

export default {
  name: "SurveyPage",
  mixins: [survey],
  components: { Survey, PageForm, QuestionForm, Draggable },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  provide() {
    return {
      editQuestion: (question) => {
        this.showDialog = true;
        this.selectedQuestion = question;
      },
      removeQuestion: (question) => {
        this.removeQuestion(question);
      },
    };
  },
  apollo: {
    surveyData: {
      query: gql`
        query Survey($id: ID!) {
          survey(id: $id) {
            id
            data
          }
        }
      `,
      variables() {
        return { id: this.id };
      },
      update(data) {
        const survey = this.buildSurvey(data.survey);
        survey.mode = "display";
        survey.setPropertyValue("isBuilderState", true);

        this.survey = survey;

        return data.survey;
      },
    },
  },
  data() {
    return {
      questionTypes: [
        { id: "checkbox", type: "checkbox", renderAs: "sv-checkbox" },
        { id: "date", type: "text", renderAs: "date-picker" },
        { id: "matrix", type: "matrix", renderAs: "sv-matrix" },
        { id: "number", type: "text", renderAs: "number" },
        { id: "radio", type: "radiogroup", renderAs: "sv-radiogroup" },
        { id: "rating", type: "rating", renderAs: "sv-rating" },
        { id: "text", type: "text", renderAs: "text-field" },
        { id: "textarea", type: "text", renderAs: "text-area" },
      ],
      survey: null,
      showDialog: false,
      selectedQuestion: null,
      saving: false,
      drag: false,
    };
  },
  methods: {
    addQuestion(type = "text", renderAs = "text-field", page = null) {
      if (page == null) page = this.survey.currentPage;

      let question = page.addNewQuestion(type);
      question.title = this.$t("survey.builder.new_question");
      question.renderAs = renderAs;
      switch (renderAs) {
        case "sv-rating":
          question.rateMin = 1;
          question.rateMax = 10;
          break;
        case "number":
          question.inputType = "number";
          question.renderAs = "text-field";
          break;
      }
    },

    addPage() {
      let page = this.survey.addNewPage();
      page.title = this.$t("survey.builder.new_page") + " " + (this.survey.visiblePages.length + 1);
      this.addQuestion("text", "text-field", page);
    },

    removePage(page) {
      this.survey.removePage(page);
    },

    removeQuestion(question) {
      question.page.removeElement(question);
    },

    save() {
      this.saving = true;
      const json = this.survey.toJSON();
      delete json.mode;
      const data = JSON.stringify(json);
      return this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateSurveyData($id: ID!, $data: String!) {
              updateSurveyData(id: $id, data: $data) {
                id
                data
              }
            }
          `,
          variables: {
            id: this.id,
            data,
          },
        })
        .then(() => {
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
        });
    },

    exit() {
      this.$router.push({ name: "Surveys" });
    },

    saveAndExit() {
      this.save().then(this.exit);
    },

    rearrangeQuestions(page, questions) {
      questions.forEach((question) => {
        page.removeElement(question);
        page.addQuestion(question);
      });
    },

    rearrangePages(survey, pages) {
      const currentPage = survey.currentPage;
      pages.forEach((page) => {
        survey.removePage(page);
        survey.addPage(page);
      });
      this.goToPage(currentPage);
    },
  },
};
</script>

<style>
.v-stepper__label {
  display: block !important;
}
</style>
