<template>
  <v-list dense>
    <v-subheader>{{ this.title }}</v-subheader>
    <v-divider></v-divider>
    <draggable
      handle=".item-handle"
      @input="$emit('input', $event)"
      :value="value"
      @start="drag = true"
      @end="drag = false"
    >
      <div v-for="(item, i) in value" :key="i">
        <v-list-item>
          <v-list-item-icon class="align-self-center mr-2 item-handle">
            <v-icon>mdi-drag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-text-field
              v-model="value[i]"
              append-outer-icon="mdi-close"
              @click:append-outer="removeItem(i)"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </div>
    </draggable>
    <v-list-item class="mt-2 d-flex justify-center">
      <v-btn icon @click="addItem($event)">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  name: "ItemsList",
  components: { Draggable },
  props: {
    value: Array,
    title: String,
  },
  data() {
    return {
      drag: false,
    };
  },
  methods: {
    removeItem(i) {
      this.$emit("input", [...this.value.slice(0, i)]);
    },
    addItem() {
      this.$emit("input", [
        ...this.value,
        this.$t("survey.builder.new_item") + " " + (this.value.length + 1),
      ]);
    },
  },
};
</script>
