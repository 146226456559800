<template>
  <v-form @submit.prevent="save">
    <v-card>
      <v-card-title> {{ $t("survey.builder.edit_question") }} </v-card-title>
      <v-divider />
      <v-card-text>
        <v-text-field :label="$t('survey.builder.question_title')" v-model="title"></v-text-field>
        <v-checkbox :label="$t('survey.builder.required')" v-model="required"></v-checkbox>
        <div v-if="question.getType() === 'checkbox' || question.getType() === 'radiogroup'">
          <items-list v-model="choices" title="Choices"></items-list>
        </div>
        <div v-if="question.getType() === 'rating'">
          <v-text-field
            :label="$t('survey.builder.rate_max')"
            type="number"
            min="5"
            max="10"
            v-model="rateMax"
          ></v-text-field>
        </div>
        <div v-if="question.getType() === 'matrix'">
          <items-list v-model="rows" :title="$t('survey.builder.rows')"></items-list>
          <items-list v-model="columns" :title="$t('survey.builder.columns')"></items-list>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" @click="close">{{ $t("dialog.cancel") }}</v-btn>
        <v-btn text color="primary" type="submit">{{ $t("dialog.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { Question } from "survey-core";
import ItemsList from "./components/ItemsList.vue";

export default {
  name: "QuestionForm",
  components: { ItemsList },
  props: {
    question: Question,
  },
  watch: {
    question() {
      this.setup();
    },
  },
  data() {
    return {
      title: null,
      choices: [],
      drag: false,
      rateMax: null,
      rows: [],
      columns: [],
      required: false
    };
  },
  computed: {
    hasChoices() {
      return this.question.getType() === "checkbox" || this.question.getType() === "radiogroup";
    },
    isRating() {
      return this.question.getType() === "rating";
    },
    isMatrix() {
      return this.question.getType() === "matrix";
    },
  },
  mounted() {
    this.setup();
  },
  methods: {
    setup() {
      this.title = this.question.title;
      this.required = this.question.isRequired;
      if (this.hasChoices) this.choices = this.question.choices.map((c) => c.value);
      if (this.isRating) this.rateMax = this.question.rateMax;
      if (this.isMatrix) {
        this.rows = this.question.rows.map((r) => r.value);
        this.columns = this.question.columns.map((c) => c.value);
      }
    },
    save() {
      this.question.title = this.title;
      this.question.isRequired = this.required;
      if (this.hasChoices) this.question.choices = this.choices;
      if (this.isRating) this.question.rateMax = this.rateMax;
      if (this.isMatrix) {
        this.question.rows = this.rows;
        this.question.columns = this.columns;
      }
      this.$emit("close");
    },
    close() {
      this.setup();
      this.$emit("close");
    },
    removeChoice(i) {
      this.choices = this.choices.filter((_, index) => index !== i);
    },
    addChoice() {
      this.choices.push(this.$t("survey.builder.new_item") + " " + (this.choices.length + 1));
    },
  },
};
</script>
