<template>
  <div class="d-flex justify-space-between align-center">
    <v-list-item class="pl-0">
      <div class="flex-0">
        <slot name="prepend"></slot>
      </div>
      <span v-if="!editing">{{ page.title }}</span>
      <v-text-field
        ref="input"
        @keyup.enter="finishEditing"
        @keyup.esc="cancelEditing"
        @blur="finishEditing"
        dense
        label="Page title"
        hide-details
        v-if="editing"
        v-model="tempValue"
      >
        <template v-slot:append-outer>
          <v-btn icon small @click="cancelEditing"><v-icon small>mdi-close</v-icon></v-btn>
          <v-btn icon small @click="finishEditing"><v-icon small>mdi-check</v-icon></v-btn>
        </template>
      </v-text-field>
    </v-list-item>
    <v-btn v-if="!editing" icon small @click="startEditing"><v-icon small>mdi-pencil</v-icon></v-btn>
    <v-btn v-if="!editing" icon small @click="$emit('click', page)"><v-icon small>mdi-eye</v-icon></v-btn>
    <v-btn v-if="!editing" icon small @click="$emit('delete', page)"><v-icon small>mdi-delete</v-icon></v-btn>
  </div>
</template>

<script>
export default {
  props: ["page"],
  data() {
    return {
      editing: false,
      tempValue: null,
    };
  },
  methods: {
    startEditing() {
      this.editing = true;
      this.tempValue = this.page.title;
      this.$nextTick(() => {
        this.$refs.input.$refs.input.focus();
      });
    },
    finishEditing() {
      this.editing = false;
      this.page.title = this.tempValue;
    },
    cancelEditing() {
      this.editing = false;
    },
  },
};
</script>
